@import "~bootstrap/scss/bootstrap";
@import "styles/colors.scss";
@import "styles/bootstrap-overrides.scss";
@import "styles/styles.scss";

.App {
  text-align: center;
  background-color: var(--scheme-teal);
  height: 100%
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header, .App-body, .App-footer {
  background-color: var(--scheme-light-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: black;
  color: var(--scheme-charcoal);
}

.App-body {
  min-height: 60vh;
}

.App-header, .App-footer {
  min-height: 20vh;
}

.App-link {
  color: var(--scheme-light-blue);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
