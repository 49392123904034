@import "./colors.scss";

a {
  text-decoration: none;
  color: var(--scheme-charcoal);
}
a:hover {
  color: var(--scheme-dark-charcoal);
}

.whole-width {
  width: 100%;
}
.half-width {
  width: 50%;
}
.third-width {
  width: 33.333%;
}
.two-third-width {
  width: 66.666%;
}

@media (min-width: 1224px) { 
  .whole-height-desktop {
    height: 100%;
  }
}

.whole-height {
  height: 100%;
}
.half-height {
  height: 50%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-content: center;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

@media (min-width: 1224px) {
  .position-fixed-desktop {
    position: fixed;
  }
}
.position-fixed {
  position: fixed;
}