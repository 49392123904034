
:root {
	--scheme-red: 	        #f54242; // red
	--scheme-orange:        #f5a142; // orange
	--scheme-yellow:        #fce588; // yellow
	--scheme-green:         #a0faa3; // green
	--scheme-teal:          #a6ffdb; // teal
	--scheme-light-blue:    #a6fffc; // light blue
	--scheme-dark-blue:	    #002b82; // dark blue
	--scheme-purple:		    #9e66ed; // purple
	--scheme-light-gray:    #ededed; // light gray
	--scheme-mid-gray:      #cdcdcd; // gray
	--scheme-dark-gray:	    #9c9c9c; // dark gray
	--scheme-charcoal:	    #696969; // charcoal
	--scheme-dark-charcoal: #474747; // charcoal
}